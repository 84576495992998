
.categoryPopup {
  z-index: 1000; /* Ensure the popup appears above the sticky header */
}
.table-container {
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 500px; /* Set a max height as needed */
}

.sticky-header th {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  background-color: white; /* Ensure background color matches your design */
  z-index: 100; /* Ensure it appears above other elements but below popups */
}
