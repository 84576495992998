.chart-container-sales {
  position: relative;
  height: 400px; /* You can change this value as needed */
  width: 100%;
  border-radius: 10px;
  /* background-color: aqua; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-container-sales .border {
  border: none; /* Remove default border */
}

.chart-container-sales .rounded223 {
  border-radius: 10px;
}

.chart-container-sales canvas {
  /* background: #f91414; */
  border-radius: 10px;
  padding: 10px;
  width: 75vw;
}
