@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.App {
  background-color: #f5f7fa;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}

/* Custom CSS for smooth height transitions */
.transition-max-height {
  transition: max-height 0.3s ease-in-out;
}
/*-----------------------------------------------------------------*/
/* Remove border from all table cells */
.MuiTableCell-root {
  border-bottom: none !important;
  padding: "2px" !important;
}

/* Remove border from all table rows */
.MuiTableRow-root {
  border: none !important;
  padding: "2px" !important;
}

/* Remove border from the entire table */
.MuiTable-root {
  border: none !important;
}
