.chart-container-camapigns {
    position: relative;
    height: 450px; /* You can change this value as needed */
    width: 100%;
    border-radius: 10px;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  .chart-container-camapigns .rounded223 {
    border-radius: 10px;
  }
  
  .chart-container-camapigns canvas {
    /* background: #f91414; */
    border-radius: 10px;
    padding: 10px;
    width: 75vw;
  }
  